import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const WorkIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="Work" />
        <p>
          Hi there! I am not currently seeking for work so I have hidden my portfolio.
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Work" />
      <div className="bread-crumb"><Link to="/work">&#47;Work</Link></div>
      <div className="index-wrapper">
      <div className="index-title-area">
        <div className="index-title-heading"><h1>WORK</h1></div>
        <div className="index-title-text-box">
          <p className="index-title-description">Rigorous research<br/>combined with design</p> 
        </div>
      </div>
      <ol className="work-post-list-area" style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          return (
            <li key={post.fields.slug}>
              <article
                className="work-post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header className="work-post-list-header">
                  <h2>
                    <Link to={post.fields.slug} itemProp="url">
                      <span className="work-post-list-header-text" itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <small>{post.frontmatter.timeframe}</small><br/>
                  <small><span 
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.company,
                      
                  }}
                  itemProp="company" 
                  /></small>
                </header>
                <section className="work-post-list-description">
                  <div><Link to={post.fields.slug} itemProp="url"> <img className="work-image" src="https://via.placeholder.com/350"></img></Link></div> 
                  <div className="work-post-list-description-text">
                        <Link to={post.fields.slug} itemProp="url">
                          <p 
                            dangerouslySetInnerHTML={{
                              __html: post.frontmatter.description || post.excerpt,
                              }}
                            itemProp="description" 
                            />
                        </Link>
                  </div>
                </section>
              </article>
            </li>
          )
        })}
      </ol>
      </div>
    </Layout>
  )
}

export default WorkIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(content)/(work)/"}}, sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`